import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import PathwaySettingsLabel from "@/admin/pathways/settings/PathwaySettingsLabel"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { useProductLabel } from "@/product/util/hook/useProductLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import MediaUploadButton from "@components/media/upload/MediaUploadButton"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoLink,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { Grid, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { generatePath } from "react-router-dom"
interface ExperienceSettingsMetaDetailsFormFieldsProps extends TestIDProps {
  form:
    | FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
    | PathwaySettingsFormStore
  productType?: Extract<ProductType, "course" | "pathway">
}

function ExperienceSettingsMetaDetailsFormFields({
  form,
  productType = "course",
}: ExperienceSettingsMetaDetailsFormFieldsProps) {
  const membersLabel = useLabel("organization_member")
  const productLabel = useProductLabel(productType).singular
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isOpen && ref.current)
      ref.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      })
  }, [isOpen])

  // Auto-open the meta details section if specified in the drawer params
  const experienceDrawer = useGlobalDrawer("experienceSettings")
  const pathwayDrawer = useGlobalDrawer("pathwaySettings")
  const drawer = pathwayDrawer.isOpen ? pathwayDrawer : experienceDrawer
  const sectionParam =
    experienceDrawer.params.experienceSettingsSection ||
    pathwayDrawer.params.pathwaySettingsSection
  useEffect(() => {
    if (sectionParam === "meta-details") {
      setIsOpen(true)
      drawer.setParams(
        { experienceSettingsSection: undefined, pathwaySettingsSection: undefined },
        "replace"
      )
    }
  }, [drawer, sectionParam])

  const tooltip = `The registration page is the main way ${membersLabel.plural} (and non-${membersLabel.plural}) can join the ${productLabel}. You can edit the registration page to market your ${productLabel}. Open the registration page link in an Incognito window to see how logged-out ${membersLabel.plural} would see the ${productLabel}.`

  return (
    <>
      <DiscoFormControl
        label={
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid item xs={"auto"}>
              {productType === "pathway" ? (
                <PathwaySettingsLabel
                  title={"Pathway Registration Page"}
                  tooltipContent={tooltip}
                />
              ) : (
                <ExperienceSettingsLabel
                  title={"Registration Page"}
                  sectionId={"experience_registration_page"}
                  tooltipContent={tooltip}
                />
              )}
            </Grid>
            <Grid item xs={"auto"}>
              <Grid container>
                <DiscoIconButton
                  onClick={() => setIsOpen((prev) => !prev)}
                  style={{ padding: theme.spacing(0.5) }}
                  testid={
                    "ExperienceSettingsMetaDetailsFormFields.registration-page.settings.button"
                  }
                >
                  <DiscoIcon icon={"settings"} />
                </DiscoIconButton>
                <DiscoIconButton
                  testid={
                    "ExperienceSettingsMetaDetailsFormFields.registration-page.edit.button"
                  }
                  onClick={() =>
                    window.open(
                      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                        productSlug: form.initialState.slug!,
                      }),
                      "_blank"
                    )
                  }
                  style={{ padding: theme.spacing(0.5) }}
                >
                  <DiscoIcon icon={"edit"} />
                </DiscoIconButton>
              </Grid>
            </Grid>
          </Grid>
        }
        sublabel={`The registration page is what ${membersLabel.plural} see when registering for the ${productLabel}.`}
        testid={"ExperienceSettingsMetaDetailsFormFields.registration-page.form-control"}
        marginBottom={0}
      >
        {form.state.registrationAvailability !== "hidden" && (
          <DiscoSection
            className={classes.metaPreview}
            testid={
              "ExperienceSettingsMetaDetailsFormFields.registration-page.meta-image-preview"
            }
          >
            <DiscoImage
              className={classes.metaImagePreview}
              src={form.state.landingPage?.metaImageUrl || form.state.cover!}
            />

            <div className={classes.metaDetails}>
              <DiscoText variant={"body-md-600"}>
                {form.state.landingPage?.metaTitle || form.state.name}
              </DiscoText>
              <DiscoText color={"text.secondary"}>
                {form.state.landingPage?.metaDescription || form.state.description}
              </DiscoText>

              <DiscoLink
                target={"_blank"}
                to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                  productSlug: form.initialState.slug!,
                })}
              >
                <DiscoText
                  variant={"body-xs-500"}
                  color={"groovy.blue.400"}
                  className={classes.url}
                >
                  {`${window.location.host}${generatePath(
                    ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT,
                    {
                      productSlug:
                        "experienceUrl" in form.state
                          ? form.state.experienceUrl!
                          : form.state.slug!,
                    }
                  )}`}
                </DiscoText>
              </DiscoLink>
            </div>
          </DiscoSection>
        )}
      </DiscoFormControl>

      {isOpen && (
        <DiscoSection
          ref={ref}
          groovyDepths={"insideCard"}
          paddingBottom={1}
          marginTop={2.5}
        >
          <DiscoText variant={"body-md-600"} marginBottom={2}>
            {"SEO & Social Share Settings"}
          </DiscoText>
          <DiscoFormControl
            label={"Meta Title"}
            error={Boolean(form.errorsByField.metaTitle)}
            errorMessages={form.errorsByField.metaTitle}
          >
            <DiscoInput
              data-testid={`ExperienceSettingsDetailsFormFields.metaTitle`}
              value={form.state.landingPage?.metaTitle}
              onChange={(e) => (form.state.landingPage!.metaTitle = e.target.value)}
              fullWidth
            />
          </DiscoFormControl>

          <DiscoFormControl
            label={"Meta Description"}
            error={Boolean(form.errorsByField.metaDescription)}
            errorMessages={form.errorsByField.metaDescription}
          >
            <DiscoInput
              data-testid={`ExperienceSettingsDetailsFormFields.mmetaDescriptionetaTitle`}
              value={form.state.landingPage?.metaDescription}
              onChange={(e) => (form.state.landingPage!.metaDescription = e.target.value)}
              fullWidth
              multiline
              minRows={3}
            />
          </DiscoFormControl>

          <DiscoFormControl
            label={"Social Share Image"}
            error={Boolean(form.errorsByField.metaImageUrl)}
            errorMessages={form.errorsByField.metaImageUrl}
          >
            {form.state.landingPage?.metaImageUrl ? (
              <Grid container spacing={2}>
                <Grid item>
                  <DiscoImage
                    className={classes.image}
                    src={form.state.landingPage.metaImageUrl}
                  />
                </Grid>
                <Grid item xs container alignItems={"center"} spacing={2}>
                  <Grid item>
                    <MediaUploadButton
                      accept={IMAGE_FILE_TYPES}
                      onSuccess={(result) =>
                        (form.state.landingPage!.metaImageUrl = result.url)
                      }
                    >
                      {"Change"}
                    </MediaUploadButton>
                  </Grid>
                  <Grid item>
                    <DiscoButton
                      color={"grey"}
                      variant={"outlined"}
                      onClick={() => {
                        form.state.landingPage!.metaImageAssetId = null
                        form.state.landingPage!.metaImageUrl = null
                      }}
                    >
                      {"Remove"}
                    </DiscoButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <FileDropzone
                message={"Drag and drop an image"}
                dropzoneOptions={{
                  accept: IMAGE_FILE_TYPES,
                }}
                onUpload={(result) => {
                  form.state.landingPage!.metaImageUrl = result.url
                  form.state.landingPage!.metaImageAssetId = result?.id
                }}
                onMediaSelect={(result) => {
                  form.state.landingPage!.metaImageUrl = result.url
                  form.state.landingPage!.metaImageAssetId = result?.id
                }}
                allowedFileTypes={["image"]}
              />
            )}
          </DiscoFormControl>
        </DiscoSection>
      )}
    </>
  )
}
const useStyles = makeUseStyles((theme) => ({
  image: {
    display: "block",
    height: "160px",
  },
  metaPreview: {
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    display: "flex",
    padding: theme.spacing(1.5),
    gap: theme.spacing(3),
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
    },
  },
  metaImagePreview: {
    height: "125px",
    display: "block",
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  metaDetails: {
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  url: {
    wordBreak: "break-all",
  },
}))
export default observer(ExperienceSettingsMetaDetailsFormFields)
