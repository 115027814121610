import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import { Spacing } from "@assets/style/appMuiTheme"
import { DiscoAlert, DiscoLink, DiscoText } from "@disco-ui"

function ProductVisibilityBanner(spacing: Spacing) {
  const activeProduct = useActiveProduct()!
  const isUnpublished = activeProduct.status === "draft"
  const canPublish = activeProduct.viewerPermissions.has("product_status.manage")
  const memberLabel = useLabel("organization_member")
  const isViewingAsMember = useIsAdminViewingAsMember()

  // Hide the banner if the product is published or in view as member mode
  if (!isUnpublished || isViewingAsMember) return null

  return (
    <DiscoAlert
      {...spacing}
      severity={"warning"}
      data-testid={"ProductVisibilityBanner.alert"}
      message={
        <>
          <DiscoText variant={"body-sm"}>
            {`This ${activeProduct.typeLabel.toLowerCase()} is currently in draft mode and not visible to ${
              memberLabel.plural
            }. `}
            {canPublish && (
              <>
                <PublishExperienceButton>
                  {(buttonProps) => (
                    <DiscoLink
                      {...buttonProps}
                      textVariant={"body-sm"}
                      data-testid={"ProductVisibilityBanner.alert.publish"}
                    >
                      {"Publish"}
                    </DiscoLink>
                  )}
                </PublishExperienceButton>
                {` this ${activeProduct.typeLabel} to start taking registrations.`}
              </>
            )}
          </DiscoText>
        </>
      }
    />
  )
}

ProductVisibilityBanner.HEIGHT = "56px"

export default ProductVisibilityBanner
