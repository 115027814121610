import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ExperienceSettingsAccessRadioGroup from "@/product/settings/ExperienceSettingsAccessRadioGroup"
import ExperienceSettingsAutojoinFormField from "@/product/settings/ExperienceSettingsAutojoinFormField"
import ExperienceSettingsCapacityField from "@/product/settings/ExperienceSettingsCapacityField"
import ExperienceSettingsDurationFields from "@/product/settings/ExperienceSettingsDurationFields"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsMetaDetailsFormFields from "@/product/settings/ExperienceSettingsMetaDetailsFormFields"
import ExperienceSettingsRegistrationFormFields from "@/product/settings/ExperienceSettingsRegistrationFormFields"
import { ExperienceSettingsAccessRadioGroupFragment$key } from "@/product/settings/__generated__/ExperienceSettingsAccessRadioGroupFragment.graphql"
import { ExperienceSettingsDurationFieldsFragment$key } from "@/product/settings/__generated__/ExperienceSettingsDurationFieldsFragment.graphql"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoDivider, DiscoSection } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
  organizationKey: ExperienceSettingsAccessRadioGroupFragment$key
  productKey: ExperienceSettingsDurationFieldsFragment$key
}

function ExperienceSettingsAvailabilityFormFields({
  form,
  organizationKey,
  productKey,
}: Props) {
  return (
    <>
      <ExperienceSettingsAccessRadioGroup form={form} organizationKey={organizationKey} />
      <DiscoSection groovyDepths={"insideCard"}>
        <ExperienceSettingsAutojoinFormField form={form} />
        {!form.state.isAutoJoined && (
          <>
            <ExperienceSettingsCapacityField form={form} />
            <ExperienceSettingsRegistrationFormFields form={form} />
          </>
        )}
      </DiscoSection>
      <DiscoDivider marginTop={2.5} marginBottom={2.5} />
      <ExperienceSettingsMetaDetailsFormFields form={form} />
      <DiscoDivider marginTop={2.5} marginBottom={2.5} />
      <ExperienceSettingsDurationFields
        form={form}
        mode={"edit"}
        productKey={productKey}
      />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}))

export const ExperienceSettingsAvailabilityFormFieldsSkeleton = () => {
  const classes = useStyles()
  const heights = [48, 136, 72]

  return (
    <div className={classes.skeletonContainer}>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsAvailabilityFormFields),
  skeleton: ExperienceSettingsAvailabilityFormFieldsSkeleton,
})
